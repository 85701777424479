<template>
  <b-form @submit.prevent="save">
    <div class="title-actions">
      <h5 class="title">{{$t('sliderPrimary')}} - {{title}}</h5>
      <div class="btns">
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <h6>{{$t('details')}}</h6>
    <ul v-html="$t('textChangeImgSlider')" class="mb-3"></ul>
    <div class="form-group">
      <label for="file">{{$t('findImage')}}</label>
      <b-form-file v-model="file" :lang="lang" :placeholder="$t('findImage')" accept="image/jpeg" @change="imagePreview" />
      <img v-if="imgPath" :src="imgPath" :alt="slider.name" class="img-thumbnail mt-3">
    </div>
    <div class="row">
      <div class="form-group col-12">
        <label for="title">{{$t('title')}}</label>
        <b-form-input v-validate="'max:50'" name="title" id="title" v-model="slider.title" :state="validateState('title')" />
      </div>
      <div class="form-group col-12 col-md-9">
        <label for="url">Url</label>
        <b-form-input v-validate="'max:190'" name="url" id="url" v-model="slider.url" :state="validateState('url')" />
      </div>
      <div class="form-group col-12 col-md-3">
        <label for="position">{{$t('position')}}</label>
        <b-form-input type="number" v-validate="'required|numeric'" name="position" id="position" v-model="slider.position" :state="validateState('position')" />
      </div>
    </div>
    <b-button type="submit" variant="primary">{{$t('save')}}</b-button>
    <b-button class="ml-3" @click="$router.go(-1)">{{$t('return')}}</b-button>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      file: null,
      imgPath: '',
      title: '',
      console: this.$route.params.console
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'sliders',
      'slider'
    ])
  },
  mounted() {
    const sliderId = this.$route.params.sliderId
    if (!sliderId) {
      this.imgPath = ''
      this.title = this.$t('create')
    } else {
      this.imgPath = this.slider.image
      this.title = this.$t('edit')
    }
    if (this.sliders.length < 1) {
      this.$router.push({ name: 'Slider', params: { lang: this.lang } })
    }
  },
  methods: {
    save () {
      const sliderId = this.$route.params.sliderId
      let payload = new FormData()
      if (this.file) {
        payload.append('image', this.file)
      }
      payload.append('position', this.slider.position)
      payload.append('title', this.slider.title)
      payload.append('url', this.slider.url ? this.slider.url : '')
      if (!sliderId) {
        const path = `auth/${this.lang}/${this.console}/slider/store`
        this.$axios.post(path, payload).then((response) => {
          const data = response.data.data
          this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
          this.$store.dispatch('ADD_SLIDER', data)
          this.$router.push({ name: 'Slider', params: { lang: this.lang } })
        })
      } else {
        const path = `auth/${this.lang}/${this.console}/slider/${sliderId}/update`
        this.$axios.post(path, payload).then((response) => {
          const data = response.data.data
          this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
          this.$store.dispatch('UPDATE_SLIDER', data)
          this.$router.push({ name: 'Slider', params: { lang: this.lang } })
        })
      }
    },
    imagePreview (e) {
      const input = e.target
      this.file = input.files[0]
      const _self = this
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = function (e) {
          _self.imgPath = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    }
  }
}
</script>
